import Menu from '../menu/Menu';
import './thanks.css';


function Thanks(){
    return(
        <div className='thanks-wrapper'>
            <Menu />
            <div className='thanks-content'>

            <div className='thanks-h2'>
                Thank You!
            </div>
            <div className='thanks-paragraph'>We have received your request for a quote.
            We will reach out to you in the next 24-48 hours.</div>
            </div>

        </div>
    )
}

export default Thanks