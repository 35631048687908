import React, { useState } from 'react';
import Menu from "../menu/Menu"
import guy from '../../images/guy.png';
import './contact.css';


const Contact = () => {

    const [enteredName, setEnteredName] = useState('')
    const [enteredEmail, setEnteredEmail] = useState('');
    const [enteredPhone, setEnteredPhone] = useState('');
    const [setComment, setEnteredComment] = useState('')
    const [validation, setValidation] = useState(true);



    const nameChangeHandler = (event) => {
        setEnteredName(event.target.value)
    };



    const phoneChangeHandler = (event) => {
        setEnteredPhone(event.target.value)
    }

    const emailChangeHandler = (event) => {
        setEnteredEmail(event.target.value)
    }

    const commentChangeHandler = (event) => {
        setEnteredComment(event.target.value)
    }


    function handleSubmit() {

        let email = enteredEmail
        let phone = enteredPhone
        if(email.includes('@'&&'.') && phone.length>9){
            setValidation(true);

            fetch('/api/sendEmail', {
                method: 'POST',
                mode: "cors",
                redirect: 'follow',
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify(
                    {
                        name: enteredName,
                        phone: enteredPhone,
                        email: enteredEmail,
                        comment: setComment
                    })
                
            


             }
             ).then(()=>{
                window.location.assign('/thanks')
             })
             }else{
                setValidation(false)
             }}



    return (
        <div className="contact-wrapper">
            <Menu />
            <div className="contact-h2">Book your photobooth now!</div>
            <img className='guy-pic'src={guy} alt='stupid' />
            <div className={validation? "validation":"validation false"}> Looks like you missed a few things.  Please fill out the form completely.</div>
            <div className='form-wrapper'>
                <div className='name-wrapper'>
                    <div>Name:</div><div> <input type='text' onChange={nameChangeHandler}></input></div>
                </div>
                <div className='email-wrapper'>
                    <div>Email</div><div><input type='text' onChange={emailChangeHandler}></input></div>
                </div>
                <div className='phone-wrapper'>
                    <div>Phone</div><div><input type='text' onChange={phoneChangeHandler}></input></div>
                </div>
                <div className='comment-wrapper'>
                    <div>Comment:</div><div><textarea className="comment-individual"type='text' onChange={commentChangeHandler}></textarea></div>
                </div>
                    <button className="contact-button"onClick={handleSubmit}>Submit</button>




            </div>
        </div>
    )

}

export default Contact