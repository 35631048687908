import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './menu.css';
import navlog from '../../images/navlog.png';
import brgr from '../../images/hamburger.png';

function Menu(){

    const [userInput, setUserInput] = useState({
        brgrClick: false
    });

    const brgrChange = () =>{
        setUserInput((prev)=>{
            return {...prev, brgrClick: !userInput.brgrClick}
        })
    }

    return(
        <div className="menu-wrapper"> 
        <div className="burger-wrapper" onClick={brgrChange}>

        <img src={brgr} className="brgr" alt='gay'/>
        </div>
        <div className={userInput.brgrClick ? "menu-items-visible" : ' menu-items-visible menu-items-hidden'}>
            <div className="menu-home"><Link to = '/'>Home</Link></div>
            <div className="menu-about"><Link to = '/about'>About</Link></div>
            <div className='navlog'><img src={navlog} alt="stupid"/></div>
            <div className='menu-package'><Link to='/prices'>Prices</Link></div>
            <div className='menu-contact'><Link to = '/contact'>Contact</Link></div>
            {/* <div className='menu-snapcamper'><Link to ='/snapcamper'>SnapCamper</Link></div>
            <div className='menu-more'>More</div> */}

        </div>

        </div>
    )
}

export default Menu