import Splash from '../splash/Splash.js';
import Menu from '../menu/Menu.js';
import Utah from '../../images/home1.png'
import Banner from '../../images/home2.png'
import './home.css';

function Home(){
    return(
        <div>
            <Menu />
            <Splash />
                <div className="home-wrapper">
                    <div className='home-ban'>
                        <img src ={Banner} className="home-ban" alt=''/>
                        <div className='home-ban-text'>
                            <div className="home-ban-h2">Utah's Photobooth Company</div>
                            < div className='home-ban-para'>Bring your event to life with utah Photobooth Company.  Capture the smiles at your event with state-of-the-art photobooths that will elevate your event to Utah levels.</div>
                        </div>
                    </div>
                    <div className='home-images'>

                    <div className='home-ban-image'><img src ={Utah} className="home-utah" alt=''/></div>
                    {/* <div className='home-carousel'>IDK.  gotta put the carousel here</div> */}
                    </div>
                    

                </div>
        </div>
    )
}

export default Home