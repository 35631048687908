import Menu from '../menu/Menu.js'
import './prices.css';
import headphones from '../../images/headphones.jpeg';
import cupcakes from '../../images/cupcakes.jpeg';
import kiss from '../../images/PBkiss.jpeg';


function Prices() {
    return (
        <div className='prices-wrapper'>
            <Menu />
            <div className='pricing-guide-wrapper'>
                <div className='pricing-guide-head'>
                    Pricing
                </div>
                <div className="pricing-guide-items">
                    <div className='pricing-guide-items-head'>
                        What you get:
                    </div>
                    <div className="pricing-guide-individual-items">

                        <div>- 3 hour rental ($100/additional hour)</div>
                        <div>- Receive photos by email and text</div>
                        <div>- Props</div>
                        <div>- Custom overlay</div>
                        <div>- Custom live gallery</div>
                        <div>- Physical background</div>
                        <div>- Capture options:
                            <p>- Photo</p>
                            <p>- Video</p>
                            <p>- GIF</p>
                        </div>
                    </div>
                    <div className='pricing-guide-price'>
                        Only $400
                    </div>
                </div>

                <div className='fun-bundle-wrapper'>
                    <div className='fun-bundle-header'>
                        <div className='fun-bundle-title'>
                            The Fun Bundle
                        </div>
                    </div>
                    <div className='fun-bundle-content'>
                        <div className='fun-bundle-sub'>
                            This is what you've been looking for.
                        </div>
                        <div className='fun-bundle-text'>
                            We have partnered with a few other local companies to provide you with the ultimate wedding bunding.  You get a cake, DJ and photobooth all for the low cost of $1195.
                        </div>
                        <div className='fun-bundle-companies'>

                            <div className='fun-bundle-company-1'>
                                <div className='company-image'>
                                    <img className='business-image'src={cupcakes} alt='whatever' />
                                </div>
                                <div className='company-header'>
                                    Awesome Wedding Cakes by Sam Keele

                                </div>
                                <div className='company-about'>
                                    An accomplished, award winning cake decorator, known and respected in the baking industry.

                                    Order your wedding cake with confidence that it will look beautiful and taste delicious.
                                </div>
                                <div className='company-link'>
                                    <a href="http://awesomeweddingcakescheap.com/" target="_blank" rel="noopener noreferrer">
                                        Click here to learn more
                                        </a>
                                </div>
                            </div>
                            <div className='fun-bundle-company-2'>
                                <div className='company-image'>
                                    <img className='business-image' src={headphones} alt='whatever' />
                                </div>
                                <div className='company-header'>

                                    DJ Applestar
                                </div>
                                <div className='company-about'>
                                    The DJ that you have come to know and love, coming in hot at a price you simply can't say no to.
                                </div>
                                <div className='company-link'>
                                <a href="https://www.drapplestar.com/" target="_blank" rel="noopener noreferrer">
                                        Click here to learn more
                                        </a>
                                </div>
                            </div>
                            <div className='fun-bundle-company-3'>
                                <div className='company-image'>
                                    <img className='business-image' src={kiss} alt='whatever' />
                                </div>
                                <div className='company-header'>
                                    Utah PhotoBooth Company

                                </div>
                                <div className='company-about'>
                                    Customize your event's photobooth the way you want it. With Utah Photobooth Company's robust photobooth platform, make your event your own.
                                </div>
                                <div className='company-link'>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )


}

export default Prices