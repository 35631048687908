import splash from '../../images/splash.png';
import logo from '../../images/logow.png';
import './splash.css';

export default function Splash(){
    return(
        <div className='splash-wrapper'> 
            <img src = {logo} className='splash-logo'/>

        </div>
    )
}