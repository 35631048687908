import Menu from '../menu/Menu.js';
import './snapcamper.css';


function Snapcamper(){

    return(
        <div className="thanks-wrapper">
            <Menu />
            <div className='thanks-h2'>
                SnapCamper
            </div>
            <div className="SC-paragraph">
                We're preparing something really exciting.  
                Stay tuned to see what magic we've got up our sleeves.
            </div>
        </div>
    )
}

export default Snapcamper;